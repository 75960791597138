document.addEventListener("DOMContentLoaded", function() {
  let markerCity = document.getElementById('marker-city');
  let markerBranchManager = document.getElementById('marker-branch-manager');
  let markerGraduationYear = document.getElementById('marker-graduation-year');
  let markerJob = document.getElementById('marker-job');
  let markerEmail = document.getElementById('marker-email');
  let hideBeforeClick = document.querySelectorAll('.hide-before-click');
  let hideAfterClick = document.querySelectorAll('.hide-after-click')[0];

  let lilleMarker = document.getElementById('lille-marker');
  let bruxellesMarker = document.getElementById('bruxelles-marker');
  let parisMarker = document.getElementById('paris-marker');
  let rennesMarker = document.getElementById('rennes-marker');
  let brestMarker = document.getElementById('brest-marker');
  let bordeauxMarker = document.getElementById('bordeaux-marker');
  let nantesMarker = document.getElementById('nantes-marker');
  let lyonMarker = document.getElementById('lyon-marker');
  let marseilleMarker = document.getElementById('marseille-marker');
  let saintMaloMarker = document.getElementById('saint-malo-marker');
  let caenMarker = document.getElementById('caen-marker');
  let leHavreMarker = document.getElementById('le-havre-marker');
  let rouenMarker = document.getElementById('rouen-marker');
  let leMansMarker = document.getElementById('le-mans-marker');
  let laRochelleMarker = document.getElementById('la-rochelle-marker');
  let saintBrieucMarker = document.getElementById('saint-brieuc-marker');
  let quimperMarker = document.getElementById('quimper-marker');
  let vannesMarker = document.getElementById('vannes-marker');
  let lorientMarker = document.getElementById('lorient-marker');
  let strasbourgMarker = document.getElementById('strasbourg-marker');
  let grenobleMarker = document.getElementById('grenoble-marker');
  let montpellierMarker = document.getElementById('montpellier-marker');
  let toulouseMarker = document.getElementById('toulouse-marker');
  let amiensMarker = document.getElementById('amiens-marker');
  let saintNazaireMarker = document.getElementById('saint-nazaire-marker');
  let niceMarker = document.getElementById('nice-marker');
  let toulonMarker = document.getElementById('toulon-marker');
  let reimsMarker = document.getElementById('reims-marker');
  let aixEnProvenceMarker = document.getElementById('aix-en-provence-marker');
  let saintEtienneMarker = document.getElementById('saint-etienne-marker');
  let dijonMarker = document.getElementById('dijon-marker');
  let angersMarker = document.getElementById('angers-marker');
  let toursMarker = document.getElementById('tours-marker');
  let poitiersMarker = document.getElementById('poitiers-marker');
  let perpignanMarker = document.getElementById('perpignan-marker');
  let nimesMarker = document.getElementById('nimes-marker');
  let annecyMarker = document.getElementById('annecy-marker');
  let bastiaMarker = document.getElementById('bastia-marker');
  let ajaccioMarker = document.getElementById('ajaccio-marker');
  let metzMarker = document.getElementById('metz-marker');
  let besanconMarker = document.getElementById('besancon-marker');
  let orelansMarker = document.getElementById('orleans-marker');
  let mulhouseMarker = document.getElementById('mulhouse-marker');
  let nancyMarker = document.getElementById('nancy-marker');
  let avignonMarker = document.getElementById('avignon-marker');
  let calaisMarker = document.getElementById('calais-marker');
  let dunkerqueMarker = document.getElementById('dunkerque-marker');
  let lavalMarker = document.getElementById('laval-marker');

  if (markerCity){
    [lilleMarker, bruxellesMarker, parisMarker, rennesMarker, brestMarker, bordeauxMarker, nantesMarker, lyonMarker, marseilleMarker, saintMaloMarker, caenMarker, leHavreMarker, rouenMarker, leMansMarker, laRochelleMarker, saintBrieucMarker, quimperMarker, vannesMarker, lorientMarker, strasbourgMarker, grenobleMarker, montpellierMarker, toulouseMarker, amiensMarker, saintNazaireMarker, niceMarker, toulonMarker, reimsMarker, aixEnProvenceMarker, saintEtienneMarker, dijonMarker, angersMarker, toursMarker, poitiersMarker, perpignanMarker, nimesMarker, annecyMarker, bastiaMarker, ajaccioMarker, metzMarker, besanconMarker, orelansMarker, mulhouseMarker, nancyMarker, avignonMarker, calaisMarker, dunkerqueMarker, lavalMarker].forEach(item => {
      if (item != null) {
        item.addEventListener('click', event => {
          markerCity.innerHTML = item.dataset.city ? item.dataset.city : "VILLE"
          markerBranchManager.innerHTML = item.dataset.branchmanager ? JSON.parse(item.dataset.branchmanager) : "Prénom NOM"
          markerEmail.innerHTML = item.dataset.email ? item.dataset.email : "contact@sciencesporennes-alumni.bzh"
          markerGraduationYear.innerHTML = item.dataset.schoolyearend ? JSON.parse(item.dataset.schoolyearend.replace(/nil/g, '"?"')) : "Année du diplôme"
            if (item.dataset.occupation) {
              markerJob.innerHTML = JSON.parse(item.dataset.occupation).map(value =>
                typeof value === 'string' ? value.substring(0, 50) : String(value).substring(0, 50) + "..."
              );
            } else {
              markerJob.innerHTML = "?"
            }
          hideBeforeClick.forEach(item => {
            item.classList.remove("notification-invisible")
          })
          hideAfterClick.classList.add("notification-invisible")
        })
      }
    })
  }
  
});